<template>
  <div class="m-4">
    <b-row>
      <b-col>
        <h2 class="mb-4">My Collection</h2>
      </b-col>
      <b-col class="text-right"> <b-button variant="primary" :to="{ name: 'add' }">
          <BIconPlusLg class="mr-1" /> Add Record
        </b-button>
      </b-col>
    </b-row>
    <h2></h2>
    <b-alert variant="warning" :show="!recordCount" class="m-5">
      You don't have any records added. Click <b-link :to="{ name: 'add' }">Add Record</b-link> to add your first record.
    </b-alert>

    <div v-if="recordCount">
      <div class="controls mb-3" v-if="!loading">
        <div>Total: {{ totalRows }} / {{ recordCount }}</div>

        <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search" debounce="500" />

        <b-pagination size="sm" align="right" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
          aria-controls="records-table" first-number last-number pills v-if="totalRows"></b-pagination>
      </div>
      <b-table id="records-table" ref="recordTable" striped hover :filter="filter" :items="gridProvider" :fields="fields"
        :per-page="perPage" :current-page="currentPage" sort-by="createdAt" sort-desc
        @row-clicked="(item) => { showModal(item) }">
        <template #cell(action)="data">
          <b-button variant="warning" size="sm" class="mr-1" @click="openAddToPrinterModal(data.item)" v-b-tooltip.hover
            :title="`Add to Print Set`">
            <BIconPrinter />
          </b-button>
          <b-button variant="danger" size="sm" @click="deleteSong(data.item)" v-b-tooltip.hover :title="`Delete Record`">
            <BIconTrash />
          </b-button>
        </template>
        <template #cell(discogsId)="data">
          <div>
            <a :href="`https://www.discogs.com/release/${data.item.discogsId}`" target="_blank" v-if="data.item.discogsId"
              v-b-tooltip.hover :title="`Goto Discogs Page`">
              <img src="/images/discogs_icon.svg" class="icon" alt="View on Discogs" />
            </a>
          </div>
        </template>
        <template #cell(artist)="data">
          <div>
            <div v-if="data.item.artist">{{ data.item.artist }}</div>
            <div v-if="!data.item.artist">
              <div v-if="data.item.side.a.artist">
                {{ data.item.side.a.artist }}
              </div>
              <div v-if="data.item.side.b.artist">
                {{ data.item.side.b.artist }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(createdAt)="data">
          <div>
            <div>{{ new Date(data.item.createdAt).toLocaleDateString('en-us', {
              year: "numeric", month: "short", day
                : 'numeric'
            })
            }}</div>
          </div>
        </template>
        <template #cell(side)="data">
          <div>
            <div>A: {{ data.item.side.a.title }}</div>
            <div v-if="data.item.side.b">B: {{ data.item.side.b.title }}</div>
          </div>
        </template>

        <template #cell(speed)="data">
          <b-badge class="speed" :variant="data.item.speed === SPEED_45 ? 'success' : 'secondary'"
            v-text="data.item.speed"></b-badge>
        </template>
        <!-- <template #cell(zMeta)="data">
        <div>
            <span v-if="data.item.zMeta">
              <div v-if="data.item.zMeta.styles">{{
                data.item.zMeta.styles.join(", ")
              }}</div>
              <div v-if="data.item.zMeta.genres">{{
                data.item.zMeta.genres.join(", ")
              }}</div>
            </span>
        </div>
      </template> -->
      </b-table>
      <AddToPrintModal ref="addToPrintModal" />
      <h4 class="text-center" v-if="!totalRows && recordCount && filter">No records found</h4>
      <b-pagination size="sm" align="right" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
        aria-controls="records-table" first-number last-number pills v-if="totalRows"
        @page-click="pageChange"></b-pagination>
      <EditRecord ref="editRecord" @onSave="refreshTable" />
    </div>
  </div>
</template>

<script>
import { SPEED_45 } from "@/consts";
import EditRecord from "@/components/EditRecord";
import AddToPrintModal from "@/components/inputs/addToPrintModal";
import { deleteRecord } from "@/requests";
import { searchRecords } from "@/requests";
import { BIconTrash, BIconPlusLg, BIconPrinter } from "bootstrap-vue";

export default {
  name: "records",
  data() {
    return {
      loading: true,
      SPEED_45,
      perPage: 40,
      currentPage: 1,
      totalRows: 0,
      filter: '',
      editSong: null,
      fields: [
        {
          key: "artist",
          sortable: true,
          tdClass: "font-weight-bold"
        },
        {
          key: "side",
          label: 'Tracks',
          sortable: false,
          tdClass: "font-weight-bold"
        },
        {
          key: "speed",
          sortable: true
        },
        {
          key: "year",
          sortDirection: 'desc',
          sortable: true
        },
        {
          key: "createdAt",
          label: 'Added',
          sortDirection: 'desc',
          sortable: true
        },
        {
          key: "discogsId",
          label: "Discogs",
          sortable: false
        },
        {
          key: "action",
          label: "",
          thStyle: { width: "140px" },
        },
      ],
    };
  },
  computed: {
    recordCount() {
      return this.$store.getters.getRecordCount
    },
  },
  methods: {
    gridProvider(ctx, callback) {
      searchRecords(ctx).then((data) => {
        if (data.data.data.recordSearch.length) {
          this.totalRows = data.data.data.recordSearch[0].totalCount
        } else {
          this.totalRows = 0
        }
        this.loading = false

        callback(data.data.data.recordSearch)
      })
    },
    refreshTable() {
      this.$refs.recordTable.refresh()
    },
    showModal(song) {
      this.$refs.editRecord.edit(song._id);
    },
    openAddToPrinterModal(id) {
      this.$refs.addToPrintModal.show([id])
    },
    pageChange() {
      window.scrollTo(0, 0)
    },
    deleteSong(data) {
      this.$bvModal.msgBoxConfirm(
        this.$createElement('div', {
          domProps: {
            innerHTML: `Are you sure you want to delete <strong>${data.artist} - ${data.side.a.title}?</strong>
        <br /><br /><em>Note: This will not remove this record from existing print sets or layouts.</em>` }
        })
        , {
          cancelVariant: 'outline-primary',
          okVariant: 'danger',
          title: 'Delete Record',
        })
        .then((response) => {
          if (response) {
            deleteRecord(data._id)
            this.refreshTable()
          }
        })
    }
  },

  mounted() {
    this.$store.dispatch('getRecordCount')
  },
  components: {
    EditRecord,
    AddToPrintModal,
    BIconTrash,
    BIconPlusLg,
    BIconPrinter,
  },
};
</script>
<style scoped>
.controls {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: end;
}

.speed {
  font-size: 16px;
}

.icon {
  width: 30px;
}
</style>
