import axios from "axios";
import { SPEED_78, SPEED_45 } from "@/consts";
export const findDiscogsRecord = (discogsId) =>
  axios({
    method: "get",
    url: `https://api.discogs.com/releases/${discogsId}`,
  });

export const saveToDiscogsCollection = (username, discogsId, discogsToken) =>
  axios({
    method: "post",
    headers: {
      Authorization: `Discogs token=${discogsToken}`,
    },
    url: `https://api.discogs.com/users/${username}/collection/folders/1/releases/${discogsId}`,
  });

export const convertDiscogsToForm = (response, object) => {
  object.year = response.data.year !== "0" ? response.data.year : null;
  object.artist = response.data.artists[0].name;
  object.sideA = response.data.tracklist[0].title;
  object.sideB = response.data.tracklist[1].title;

  if (response.data.formats[0].name == "Shellac") object.speed = SPEED_78;
  if (response.data.formats[0].name == "Vinyl") object.speed = SPEED_45;

};
