<template>
  <b-modal ref="editRecord" title="Edit Record" @ok="save" size="xl">
    <div v-if="record">

      <b-row>
        <b-col>
          <b-form-group id="discogsId-group" label="Discogs ID:" label-for="discogsId">
            <b-input-group>
              <b-form-input id="discogsId" v-model="record.discogsId" type="number" ref="discogsId"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-success" button @click="fetchDiscogs()" :disabled="!record.discogsId">
                  Retrieve</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <div>
            <Card45 :artist="record.artist" :sideA="record.side.a.title" :sideB="record.side.b.title"
              v-if="record.speed === SPEED_45" />
            <Card78 :artist="record.artist" :title="record.side.a.title" v-if="record.speed === SPEED_78" />
            <Card78 :artist="record.artist" :title="record.side.b.title" v-if="record.speed === SPEED_78"
              class="mt-1 mb-1" />
          </div>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="artist-group" label="Artist*:" label-for="artist">
            <b-form-input id="artist" v-model.trim="record.artist"
              @blur="setValidationTouched('artist', $event.target.value)" :state="validateState('artist')" required />
            <b-form-invalid-feedback v-if="!$v.record.artist.required && $v.record.artist.$dirty">Artist is required
            </b-form-invalid-feedback>
          </b-form-group>

        </b-col>
        <b-col>
          <b-form-group id="acquired-group" label="Acquired Date:" label-for="acquired">
            <b-form-datepicker id="acquired" v-model="record.acquired" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="sideatitle-group" label="Side A:" label-for="sideatitle">
            <b-form-input id="sideatitle" v-model="record.side.a.title"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="year-group" label="Year:" label-for="year">
            <b-form-input id="year" v-model="record.year" type="number" ref="discogsId">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <BIconArrowDownUp @click="switchTitles()" style="float: right" />
          <b-form-group id="sidebtitle-group" label="Side B:" label-for="sidebtitle">
            <b-form-input id="sidebtitle" v-model="record.side.b.title"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-button-group>

          </b-button-group>
          <b-form-group id="speed-group" label="Speed:*" label-for="speed">
            <b-button id="speed" :variant="`${record.speed === SPEED_45 ? '' : 'outline-'}success`"
              @click="record.speed = SPEED_45" class="mr-1">{{ SPEED_45 }}</b-button>
            <b-button :variant="`${record.speed === SPEED_78 ? '' : 'outline-'}success`"
              @click="record.speed = SPEED_78">{{ SPEED_78 }}</b-button>
          </b-form-group>
        </b-col>
      </b-row>

    </div>
  </b-modal>
</template>

<script>
import { SPEED_78, SPEED_45 } from "@/consts";
import { BIconArrowDownUp } from "bootstrap-vue";
import { updateRecord } from "@/requests";
import Card45 from "@/components/45/Card";
import Card78 from "@/components/78/Card";
import {
  GET_RECORD,
} from "@/queries";
import makeRequest from "@/requests/makeRequest";
import { findDiscogsRecord, convertDiscogsToForm } from "../discogs";
import { required } from 'vuelidate/lib/validators'

export default {
  name: "EditRecordComponent",
  data() {
    return {
      record: null,
      SPEED_78,
      SPEED_45,
      speed: [SPEED_45, SPEED_78],
    };
  },

  validations: {
    record: {
      artist: {
        required
      },
      sideA: {
        required
      },
      sideB: {
        required
      },
    }
  },
  methods: {
    setValidationTouched(field, value) {
      this.record[field] = value
      this.$v.record[field].$touch()
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.record[name];
      return ($dirty && $error) ? false : null;
    },
    resetRecord() {
      this.record = null
    },
    edit(id) {
      this.$refs.editRecord.show()
      this.resetRecord()
      makeRequest(GET_RECORD, { id }).then((response) => {
        this.record = response.data.data.record
      })
    },
    fetchDiscogs() {
      this.$refs.editRecord.show()
      findDiscogsRecord(this.record.discogsId).then((response) => {
        convertDiscogsToForm(response, this.record)
        delete this.record.sideA
        delete this.record.sideB
        this.record.side.a.title = response.data.tracklist[0].title;
        this.record.side.b.title = response.data.tracklist[1].title;
      })
    },
    save() {
      updateRecord(this.record).then(() => {
        this.$emit('onSave')
      })
    },
    switchTitles() {
      const a = this.record.side.a.title;
      const b = this.record.side.b.title;
      this.record.side.a.title = b;
      this.record.side.b.title = a;
    },
  },
  components: {
    BIconArrowDownUp,
    Card45,
    Card78,
  },
};
</script>
