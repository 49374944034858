<template>
  <div>
    <b-modal id="printSetModal" ref="printSetModal" size="md" title="Add title strips to print set" hide-footer
      ok-title="Save">
      <b-form @submit="onSubmit">
        <b-row>
          <b-col>
            <p v-if="idsToAdd.length">Choose a print set to add {{ idsToAdd.length === 1 ? 'this title strip' : `these
              ${idsToAdd.length} title strips` }} to:</p>
            <b-alert variant="warning" :show="!prints.length">
              You don't have any print sets to add these title strips to.
              Goto <b-link :to="{ name: 'print' }">Print Shop</b-link> to add a print set.
            </b-alert>
            <b-alert variant="warning" :show="!idsToAdd.length">
              You don't have any records in this Layout. Add some records to add you the layout.
            </b-alert>
            <b-list-group :v-if="idsToAdd.length">
              <b-list-group-item v-for="(print, index) in prints" :key="print._id" class="item" @click="() => {
                active = index;
              }" :active="active === index">
                {{ print.title }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right mt-4">
            <b-button class="mr-2" @click="() => { this.$refs.printSetModal.hide() }"
              variant="outline-primary">Cancel</b-button>
            <b-button type="submit" variant="primary" :disabled="!prints.length">Add</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { addRecordsToPrintSet } from "@/requests";
// import router from '@/router';

export default {
  name: "AddToPrintModal",

  data() {
    return {
      slots: [],
      titleStrip: {},
      active: 0
    };
  },
  methods: {
    show(slots, titleStrip) {
      if (slots) {
        this.slots = slots;
      }
      if (titleStrip) {
        this.titleStrip = titleStrip;
      }
      this.$refs.printSetModal.show();
    },
    onSubmit(ev) {
      ev.preventDefault()
      const id = this.prints[this.active]._id
      const design = this.titleStrip
      addRecordsToPrintSet(id,
        this.idsToAdd,
        design).then(() => {
          this.$emit('onSuccess', id);
        });
      this.$refs.printSetModal.hide();
    },
  },
  computed: {
    prints() {
      return this.$store.getters.getPrints.filter((print) => print.status === "OPEN");
    },
    idsToAdd() {
      return this.slots.flat().filter((record) => record._id !== null).map((obj) => obj._id)
    }
  },
  mounted() {
    this.$store.dispatch("getPrints");
  },
};
</script>
<style scoped>
.item {
  cursor: pointer;

}
</style>
