<template>
  <div class="jukeCard">
    <div class="innerCard">
      <div class="title">{{ title }}</div><!-- :class="{warning: side.title.length > 34}"-->
      <div class="artist">{{ artist }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card78",
  props: {
    artist: String,
    title: String
  }
};
</script>

<style scoped>
.title {
  margin-top: 2px;
  margin-bottom: 3px;
  height: 15px;
  overflow: hidden;
}

.artist {
  margin-top: 2px;
  margin-bottom: 3px;
  height: 15px;
  overflow: hidden;
}

.innerCard {
  border-top: 7px solid #f32f24;
  border-bottom: 1px solid #f32f24;
  height: 100%;
  font-weight: 400;
  font-family: 'Courier Prime', Courier;
  text-transform: uppercase;
  letter-spacing: -.7px;
}

.jukeCard {
  text-align: center;
  font-family: "Courier New", serif;
  font-size: 15px;
  width: 3.25in;
  height: 0.51in;
}

.warning {
  background-color: bisque;

}
</style>
